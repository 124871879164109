export default function useDateWeek(date: Date): number {
    const weekDays = 7;
    const dayOffset = 86400000;
    const weekStart = 4;

    const tempDate = new Date(date.getTime());
    tempDate.setDate(tempDate.getDate() + weekStart - (tempDate.getDay() || weekDays));
    const yearStart = new Date(tempDate.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((tempDate.getTime() - yearStart.getTime()) / dayOffset) + 1) / weekDays);

    return weekNumber;
}
