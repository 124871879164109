<template>
    <div
class="checkbox" :class="{
        'checked': checked || intermediate,
        disabled
    }" @click.stop.prevent="onClick">
        <div class="checkbox__icon">
            <AtomIcon
                v-if="intermediate"
                name="minus"
            />
            <AtomIcon
                v-else-if="checked"
                name="check"
            />
        </div>
        <div v-if="label">
            {{ label }}
        </div>
        <AtomBlueInfo v-if="badge" :text="badge" :info-no-background="true" :left-gap="true" />
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
    modelValue?: any,
    disabled?: boolean,
    label?: string,
    badge?: string,
    checked?: boolean,
    intermediate?: boolean,
}>(), {
    badge: undefined,
    modelValue: false,
    disabled: false,
    label: '',
});

const checked = ref(props.checked);

const emit = defineEmits<{
    (e: 'update:modelValue', checkedValue: boolean, event: MouseEvent): void,
}>();

const onClick = (event: MouseEvent) => {
    if (props.disabled) {
        return;
    }

    checked.value = !checked.value;

    emit('update:modelValue', checked.value, event);
};

watch([() => props.checked, () => props.intermediate], ([newValueChecked]) => {
    checked.value = newValueChecked;
});

</script>

<style lang="scss" scoped>
.checkbox {
    display: flex;
    cursor: pointer;

    &__icon {
        margin-right: sp(xs);
        width: rem(24);
        height: rem(24);
        display: flex;
        justify-content: center;
        align-items: center;

        @include helper-border-radius(rem(5));
        @include helper-color(white);
        @include helper-color-bg(white);
        @include helper-svg-size(20);
    }

    &:hover:not(.disabled) {
        &:not(.checked) {
            .checkbox__icon {
                @include helper-border($setting-color-state-hover);
            }
        }

        &.checked {
            .checkbox__icon {
                @include helper-color-bg(select-hover);
            }
        }
    }

    &:not(.checked) {
        .checkbox__icon {
            @include helper-border($setting-color-gray-2);
        }
    }

    &.checked {
        .checkbox__icon {
            @include helper-color-bg(corporate-blue);
        }

        &.disabled {
            .checkbox__icon {
                @include helper-color-bg(gray-2);
            }
        }
    }

    &.disabled {
        cursor: default;

        .checkbox__icon {
            @include helper-border($setting-color-gray-1);
        }
    }
}
</style>
